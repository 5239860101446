$(window).scroll(function () {
    if ($(window).scrollTop() > 27) {
        $('.header-menu').addClass('fixed-top');
    }
    if ($(window).scrollTop() < 28) {
        $('.header-menu').removeClass('fixed-top');
    }
});


function openNav() {
    var e = document.getElementById("mySidenav");
    if (e.style.width == '100%') {
        e.style.width = '0px';
    } else {
        e.style.width = '100%';
    }
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}

function openSidebar(id) {

    $("#close-sidebar").show();

    closeSidebar1();
    closeSideview();

    $("#pacakageHead").empty();
    $("#pacakageAllChildList").empty();
    packageallChildList(id);
    var e = document.getElementById("mySidenav1");

    if (e.style.width == '100%') {
        e.style.width = '0px';
    } else {
        e.style.width = '100%';
        $('body').addClass("overflow-hidden");
    }

    if (window.innerWidth > 767) {
        $(".custom-scroll").mCustomScrollbar();
    }
}

function closeSidebar() {
    $("#close-sidebar").hide();
    document.getElementById("mySidenav1").style.width = "0";
    $('body').removeClass("overflow-hidden");
}

function viewDetails(parentID, childID, isHideFooter = false) {
    $("#close-sideview").show();
    // closeSidebar1();
    closeSidebar();
    $('#assetDetails').empty();
    assetList(parentID, childID);
    var e = document.getElementById("viewAssets");

    if (e.style.width == '100%') {
        // e.style.width = '0px'; 
        $('body').addClass("overflow-hidden");
    } else {
        e.style.width = '100%';
        $('body').addClass("overflow-hidden");
    }


    if (window.innerWidth > 767) {
        $(".custom-scroll").mCustomScrollbar();
    }
    if (isHideFooter) {
        $("#totalDetails").hide();
        $("#viewAssets").addClass("second-nav");
    } else {
        $("#totalDetails").show();
        $("#viewAssets").removeClass("second-nav");
    }
}

function freelancerDetails() {
    freelancerInfo = {};
    fDetailsUrl = [];
    fileUrl = [];
    skillArr = [];
    files = '';
    newFileList = [];
    newFileList2 = [];
    $("#ulList").empty();

    $("#complete_registered").hide();
    $('#sidebar-freelancer').show();
    $("#personalData").show();
    $("#sidebar-freelancer").show();
    $("#close-freelancer").show();
    $('#personalData').show();
    $('#freelancerDataFooter').show();
    $('#RegisterData').hide();
    $('#freelancerRegisterFooter').hide();

    var e = document.getElementById("freelancer");
    if (e.style.width == '100%') {
        e.style.width = '0px';
    } else {
        e.style.width = '100%';
        $('body').addClass("overflow-hidden");
    }


}


function closeFreelancer() {
    $("#close-freelancer").hide();
    document.getElementById("freelancer").style.width = "0";
    $('body').removeClass("overflow-hidden");
}

function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
}

function openSidebar1(parentID = null, childID = null) {
    let cartList = localStorage.getItem("cartList");
    cartList = JSON.parse(cartList);
    if (cartList !== null && cartList.length === 0) {
        toastr.warning('First add item in your cart');
        return false;
    }

    poolDetails();
    $("#OTPDetail-require-validation").hide();
    $('#OTPDetail-valid-validation').hide();
    $('#OTPDetail-send-new').hide();
    $('#msg1').css('display', 'none');
    $('#msg2').css('display', 'none');
    $('#msg3').css('display', 'none');
    $('#msg4').css('display', 'none');
    var tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    $('#deliveryDate').val(getFormattedDate(tomorrow))

    $("#close-sidebar1").show();
    closeSideview();
    closeSidebar();

    $('#userData').css('display', 'block');
    $('#summaryData').css('display', 'none');
    $('#paymentData').css('display', 'none');

    $("#flow1").empty();
    $("#flow2").empty();
    $("#flow3").empty();
    $("#flowHead1").empty();
    $("#thankyou").hide();
    $("#alreadyRegister").hide();

    updateCartList();

    // <a href="javascript:void(0);" class="btn btn-skyblue" onclick="javascript:checkUserData();">Accept and Continue</a>
    var e = document.getElementById("mySidebar2");

    if (e.style.width == '100%') {
        e.style.width = '0px';
        $('body').addClass("overflow-hidden");
    } else {
        e.style.width = '100%';
        $('body').addClass("overflow-hidden");
    }
    if (window.innerWidth > 767) {
        $(".custom-scroll").mCustomScrollbar();
    }

    COUNTRY_CODE.forEach(cc => {
        const option = document.createElement("option");
        option.text = cc.name;
        option.value = cc.code;
        const select = document.getElementById("countryCode");
        select.appendChild(option);
    });
}

function updateCartList() {
    $("#flow1").empty();
    $("#flowHead1").empty();

    let cartList = localStorage.getItem("cartList");
    cartList = JSON.parse(cartList);
    let totalPrice = 0;
    let totalTime = 0;
    let spidList = [];
    let parentidList = [];
    if (cartList !== null && cartList.length) {
        let childItems = [];
        ajaxResult.forEach((clo) => {
            childItems = childItems.concat(clo.Child);
        });
        cartList.forEach(cl => {
            parentidList.push(cl.leadType);
            spidList.push({ childId: cl.childId, qty: cl.qty });
            const findedObj = childItems.find((clo) => clo.id === cl.childId);
            totalTime = (totalTime + (tHours[findedObj.id] * cl.qty));
            totalPrice = (totalPrice + (tPrice[findedObj.id] * cl.qty));
            $("#flowHead1").append(`
                <div class="sidebar-list-products" >
                    <h2  onclick="viewDetails(${cl.leadType}, ${cl.childId}, true)"><span class="notranslate">${findedObj.name}</span><a >Detail</a> </h2>
                    <div class="input-data">
                    <div class="input-value">  
                        <div class="number">
                            <span id="minus" class="minus">-</span>
                            <input type="text" value="${cl.qty}" min="1" onchange="changeQty(${cl.childId}, this.value)"/>
                            <span id="plus" class="plus">+</span>
                        </div>
                    </div>
                    
                    <div class="total-value d-flex">
                        <span onclick="viewDetails(${cl.leadType}, ${cl.childId}, true)">${hoursFormat(tHours[findedObj.id] * cl.qty)}  /</span>
                        <span class="notranslate" onclick="viewDetails(${cl.leadType}, ${cl.childId}, true)">${currancyFormat(tPrice[findedObj.id] * cl.qty)} USD</span> 
                        <a href="javascript:void(0)" onclick="removeCartItem(${findedObj.id})">
                            <span class="fa fa-trash"></span>
                        </a>
                    </div>
                    </div>
                </div>
            `);
        });
    }

    userInfo.pid = parentidList;
    userInfo.spid = spidList;
    userInfo.total_price = totalPrice;
    userInfo.total_hour = totalTime;

    // $("#flowHead1").append(`
    // <h2>` + childName[childID] + `</h2>
    // <div class="total-value d-flex"><span>` + hoursFormat(userInfo.total_hour) + `&nbsp;&nbsp;/</span><span>` + currancyFormat(userInfo.total_price) + `</span>USD</div>`);

    $("#flow1").append(`
  <div class="footer-left">
    <div class="total-value d-flex">
          Total:<span>` + hoursFormat(totalTime) + ` &nbsp;/</span> <span class="notranslate"> ` + currancyFormat(totalPrice) + ` USD</span>  </div>
		  <a href="javascript:void(0);" class="text-link" data-toggle="modal" data-target="#terms-dialog-modal">View Terms & Conditions</a>
	</div>
    `);

    calculateDeliveryDate();
    if (cartList !== null && cartList.length === 0) {
        $("#btnCommercialUser").hide();
        $("#flowHead1").hide();
    } else {
        $("#btnCommercialUser").show();
        $("#flowHead1").show();
    }
}

function calculateDeliveryDate() {
    if (userInfo.total_hour > 0) {
        mainObj.calculatedDeliveryDays = 1;
        if (userInfo.total_hour > 8) {
            mainObj.calculatedDeliveryDays = userInfo.total_hour / 8;
            mainObj.calculatedDeliveryDays = Math.ceil(mainObj.calculatedDeliveryDays);
        }

        var newDay = new Date();
        newDay.setDate(newDay.getDate() + mainObj.calculatedDeliveryDays);

        let weekendDaysCnt = CalculateWeekendDays(new Date(), newDay);
        if (weekendDaysCnt > 0) {
            mainObj.calculatedDeliveryDays = parseInt(mainObj.calculatedDeliveryDays + weekendDaysCnt);
            newDay.setDate(newDay.getDate() + weekendDaysCnt);
        }

        let isWeekendDate = 0;
        if (newDay.getDay() == 0) {
            isWeekendDate = 1;
        } else if (newDay.getDay() == 6) {
            isWeekendDate = 2;
        }
        if (isWeekendDate > 0) {
            newDay.setDate(newDay.getDate() + isWeekendDate);
        }

        var dd = String(newDay.getDate()).padStart(2, '0');
        var mm = String(newDay.getMonth() + 1).padStart(2, '0');
        var yyyy = newDay.getFullYear();

        mainObj.calculatedDeliveryDate = mm + '/' + dd + '/' + yyyy;
        $('#deliveryDate').val(mainObj.calculatedDeliveryDate);
        userInfo.udd = $('#deliveryDate').val();
    } else {
        mainObj.calculatedDeliveryDate = null;
        mainObj.calculatedDeliveryDays = 0;
    }
    setMinDateWarning();
}

function setMinDateWarning() {
    let dateMsg = '';
    let minWarningDate = new Date();
    if (mainObj.calculatedDeliveryDate) {
        minWarningDate = mainObj.calculatedDeliveryDate;
        let dateDiffObj = dateDiff(new Date(), mainObj.calculatedDeliveryDate);
        dateMsg += 'The minimum delivery time is';
        if (dateDiffObj.years > 0) {
            dateMsg += ` ${dateDiffObj.years} year`;
            if (dateDiffObj.years > 1) {
                dateMsg += `s`;
            }
        }

        if (dateDiffObj.months > 0) {
            dateMsg += ` ${dateDiffObj.months} month`;
            if (dateDiffObj.months > 1) {
                dateMsg += `s`;
            }
        } else {
            if (dateDiffObj.years > 0 && dateDiffObj.days > 0) {
                dateMsg += ` 0 month`;
            }
        }

        if (dateDiffObj.days > 0) {
            dateMsg += ` ${dateDiffObj.days} day`;
            if (dateDiffObj.days > 1) {
                dateMsg += `s`;
            }
        }
    }
    $('#minDateWarning').html(dateMsg);
    console.log('minWarningDate ==> ', minWarningDate);
    $('#id_4').data("DateTimePicker").minDate(minWarningDate);
}

function dateDiff(startdate, enddate) {
    //define moments for the startdate and enddate
    var startdateMoment = moment(startdate);
    var enddateMoment = moment(enddate).add(1, 'days');

    if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
        //getting the difference in years
        var years = enddateMoment.diff(startdateMoment, 'years');

        //moment returns the total months between the two dates, subtracting the years
        var months = enddateMoment.diff(startdateMoment, 'months') - (years * 12);

        //to calculate the days, first get the previous month and then subtract it
        startdateMoment.add(years, 'years').add(months, 'months');
        var days = enddateMoment.diff(startdateMoment, 'days')

        return {
            years: years,
            months: months,
            days: days
        };
    }
    else {
        return undefined;
    }
}

function CalculateWeekendDays(fromDate, toDate) {
    var weekendDayCount = 0;

    while (fromDate < toDate) {
        fromDate.setDate(fromDate.getDate() + 1);
        if (fromDate.getDay() === 0 || fromDate.getDay() == 6) {
            ++weekendDayCount;
        }
    }

    return weekendDayCount;
}

function changeQty(childId, qty) {
    if (qty) {
        let cartList = localStorage.getItem("cartList");
        if (cartList) {
            cartList = JSON.parse(cartList);
        } else {
            cartList = [];
        }
        const objIndex = cartList.findIndex((clo) => clo.childId === childId);
        if (objIndex >= 0) {
            cartList[objIndex]['qty'] = parseInt(qty);
        }

        spidList = [];
        cartList.forEach(cl => {
            spidList.push({ childId: cl.childId, qty: cl.qty });
        });
        userInfo.spid = spidList;
        localStorage.setItem("cartList", JSON.stringify(cartList));
        updateCartList();
    } else {
        toastr.warning('Item qunatity must be greater than zero');
    }
}

function openCampSidebar() {
    $("#campdata").show();
    $("#campThankyou").hide();
    $("#close-sidebar1").show();
    $("#hideTag").show();

    var e = document.getElementById("campSmsSidebar");

    if (e.style.width == '100%') {
        e.style.width = '0px';
        $('body').addClass("overflow-hidden");
    } else {
        e.style.width = '100%';
        $('body').addClass("overflow-hidden");
    }
    if (window.innerWidth > 767) {
        $(".custom-scroll").mCustomScrollbar();
    }
}

function openThanksModal() {
    $("#campdata").hide();
    $("#campThankyou").show();
    $("#hideTag").hide();

}

function clearMarketFormValue() {
    $("#marketing_fname").val('');
    $("#marketing_email").val('');
    $("#marketing_mobile").val('');
    $("#marketing_campProfesion").val('');
}

function closeCampSmsSidebar() {
    $("#close-sidebar1").hide();
    document.getElementById("campSmsSidebar").style.width = "0";
    $('body').removeClass("overflow-hidden");
}


function openTermsSidebar() {

    // $("#campdata").show();
    // $("#campThankyou").hide();
    $("#close-terms-sidebar").show();
    // $("#hideTag").show();

    var e = document.getElementById("campTermsSidebar");

    if (e.style.width == '100%') {
        e.style.width = '0px';
        $('body').addClass("overflow-hidden");
    } else {
        e.style.width = '100%';
        $('body').addClass("overflow-hidden");
    }
    if (window.innerWidth > 767) {
        $(".custom-scroll").mCustomScrollbar();
    }
}

function closeTermsSidebar() {
    $("#close-terms-sidebar").hide();
    document.getElementById("campTermsSidebar").style.width = "0";
    // $('body').removeClass("overflow-hidden");
}

function closeSidebar1() {
    $("#close-sidebar1").hide();
    document.getElementById("mySidebar2").style.width = "0";
    $('body').removeClass("overflow-hidden");
    closeSideview();
    $("#viewAssets").removeClass("second-nav");
}

function closeSideview() {
    $("#close-sideview").hide();
    document.getElementById("viewAssets").style.width = "0";
    $('body').removeClass("overflow-hidden");
}


// Text Animation


var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 1) || 400;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) {
        delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 200;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};

window.onload = function () {
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-rotate');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #000 }";
    document.body.appendChild(css);
};


// Portfolio

filterSelection("all")

function filterSelection(c) {
    var x, i;
    x = document.getElementsByClassName("column");
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
        w3RemoveClass(x[i], "show");
        if (x[i].className.indexOf(c) > -1) w3AddClass(x[i], "show");
    }
}

function w3AddClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) == -1) {
            element.className += " " + arr2[i];
        }
    }
}

function w3RemoveClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
            arr1.splice(arr1.indexOf(arr2[i]), 1);
        }
    }
    element.className = arr1.join(" ");
}


// Add active class to the current button (highlight it)
// var btnContainer = document.getElementById("myBtnContainer");
// var btns = btnContainer.getElementsByClassName("btn");
// for (var i = 0; i < btns.length; i++) {
//     btns[i].addEventListener("click", function() {
//         var current = document.getElementsByClassName("active");
//         current[0].className = current[0].className.replace(" active", "");
//         this.className += " active";
//     });
// }

// team-sliderJS

// (function(){
//     $('.carousel-showmanymoveone .carousel-item').each(function(){
//       var itemToClone = $(this);

//       for (
//           var i=1;i<4;i++) 
//           {
//         itemToClone = itemToClone.next();

//         // wrap around if at end of item collection
//         if (!itemToClone.length) {
//           itemToClone = $(this).siblings(':first');
//         }

//         // grab item, clone, add marker class, add to collection
//         itemToClone.children(':first-child').clone()
//           .addClass("cloneditem-"+(i))
//           .appendTo($(this));
//       }
//     });
//   }());

jQuery(document).ready(function ($) {
    "use strict";
    $('#team-slide').owlCarousel({
        loop: true,
        center: false,
        items: 4,
        // margin: 30,
        autoplay: true,
        // dots:true,
        nav: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1170: {
                items: 4
            }
        }
    });

    $(document).on('click', '#minus', function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });

    $(document).on('click', '#plus', function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });
    $('.app--slider').owlCarousel({
        items: 1,
        loop: true,
        margin: 10,
        center: true,

        responsive: {

            480: {
                items: 1,
                center: false
            },
            600: {
                items: 1,
                center: false
            },
            1000: {
                items: 1
            },
            1200: {
                items: 2
            }
        }
    });
    $('.app--slider').on('mousewheel', '.owl-stage', function (e) {
        if (e.deltaY > 0) {
            $('.app--slider').trigger('next.owl');
        } else {
            $('.app--slider').trigger('prev.owl');
        }
        e.preventDefault();
    });




    // Slider

    jQuery("#carousel-gallery").owlCarousel({
        autoplay: true,
        lazyLoad: true,
        margin: 20,
        loop: true,
        dots: false,
        items: 3,
        responsiveClass: true,
        autoHeight: true,
        autoplayTimeout: 6000,
        smartSpeed: 800,
        nav: false,
        navText: ["<i class='fa fa-long-arrow-left'></i>", "<i class='fa fa-long-arrow-right'></i>"],
        onInitialized: counter, //When the plugin has initialized.
        onTranslated: counter, //When the translation of the stage has


        responsive: {
            0: {
                items: 1
            },

            600: {
                items: 2
            },

            1023: {
                items: 2
            },

            1280: {
                items: 3
            }
        }
    });

    function counter(event) {
        var element = event.target; // DOM element, in this example .owl-carousel
        var items = event.item.count; // Number of items
        var item = event.item.index + 1; // Position of the current item

        // it loop is true then reset counter from 1
        if (item > items) {
            item = item - items
        }
        $('#counter').html("<b>" + item + "</b> <span>/</span> " + items)
    }
});

AOS.init({
    duration: 1200,
})
// $(document).ready(function () {
//     var $target = $('body');
//     inView('.section').on('enter', function(el){
//         var color = $(el).attr('data-background-color');
//         $target.css('background-color', color );
//     });    
// });



jQuery(document).ready(function () {
    $('.contact-area').select2({
        allowClear: true,
        minimumResultsForSearch: Infinity,
    }).data('select2').$dropdown.addClass('my-container');

    $('.campProfesion').select2({
        allowClear: true,
        minimumResultsForSearch: Infinity,
    }).data('select2').$dropdown.addClass('my-container');

    $('.contact-area').on('select2:select', function (e) {
        var data = e.params.data;
        if (data.text == "") {
            printError("contactAreaErr", "Please select your contect area.");
        } else {
            printError("contactAreaErr", "");
            contactAreaErr = false;
        }
    });
    $('.service-purchase').select2({
        allowClear: true,
        minimumResultsForSearch: Infinity,
    }).data('select2').$dropdown.addClass('my-container service-purchases');
    $('.service-purchase-second').select2({
        allowClear: true,
        minimumResultsForSearch: Infinity,
    }).data('select2').$dropdown.addClass('my-container service-purchases');

    $('.service-purchase-second').select2({
        allowClear: true,
        minimumResultsForSearch: Infinity,
    }).data('select2').$dropdown.addClass('my-container resion-part');
});


// This will select everything with the class smoothScroll
// This should prevent problems with carousel, scrollspy, etc...
$('.smoothScroll').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top
            }, 1000); // The number here represents the speed of the scroll in milliseconds
            return false;
        }
    }
});